'use strict';

/**
 * Gets Billing Address Form Values
 * OVERRIDDEN: Because dwfrm_billing has bolt fields and specially postalCode - it overrides the actual form field input
 * So here we exclude the bolt fields, because they should not be considered, even more this is only for BT (the address
 * form is actually copied as JSON into hidden form field)
 *
 * @returns {Object} with Billing Address
 */
function getBillingAddressFormValues() {
    const billingFormData = Object.fromEntries(new FormData(document.getElementById('dwfrm_billing')));

    return Array.from(Object.entries(billingFormData)).filter(function ([key, value]) {
        return !key.includes('boltCreditCard');
    }).reduce(function (accumulator, [key, value]) {
        let elem = key.lastIndexOf('_');

        if (elem < 0) {
            accumulator[key] = value;
        } else {
            elem = key.substring(elem + 1);
            accumulator[elem] = value;
        }

        return accumulator;
    }, {});
}

/**
 * Return payment method name in lovercase
 * OVERRIDDEN: duplicate, because was never exported before
 * @param {string} paymentMethodName Payment method name
 * @returns {string} Paymnet method name
 */
function getPaymentMethodToLowerCase(paymentMethodName) {
    let paymentMethod = paymentMethodName.split('_');

    if (paymentMethod.length === 1) {
        return paymentMethodName;
    }

    paymentMethod = paymentMethod.map(function(element) {
        return element.charAt(0) + element.slice(1).toLocaleLowerCase();
    });

    return `${paymentMethod[0]} ${paymentMethod[1]}`;
}

/**
 * Appends a div to payment summary
 * OVERRIDDEN: because direct call to parent function wont work
 * @param {Element} $element HTML element
 * @param {*} text inner text
 * @returns {void}
 */
function appendDivToElement($element, text) {
    const $div = $element.cloneNode(false);
    const innerText = document.createTextNode(text);

    $div.removeAttribute('class');
    $div.appendChild(innerText);
    $element.appendChild($div);
}

/**
 * Updates checkout view
 * OVERRIDDEN: because we want to place type of card near the payment method name
 * @param {Object} e Event object
 * @param {Object} data Data object
 */
function updateCheckoutView(e, data) {
    const $paymentSummary = document.querySelector('.summary-details .js-braintree-payment-details');
    const order = data.order;
    const payment = order.billing.payment;

    if (payment?.selectedPaymentInstruments?.length === 0 || !$paymentSummary) {
        return;
    }

    payment.selectedPaymentInstruments.forEach(selectedPaymentInstrument => {
        const paymentMethodId = selectedPaymentInstrument.paymentMethod;
        const fundingSource = selectedPaymentInstrument.fundingSource;

        $paymentSummary.innerHTML = '';

        if (fundingSource === window.braintreeConstants.PP_FUNDING_SOURCE_PAYLATER) {
            appendDivToElement($paymentSummary, window.braintreeConstants.PP_PAYLATER_PAYMENT_TYPE);
        } else if (fundingSource === window.braintreeConstants.PP_FUNDING_SOURCE_CARD) {
            appendDivToElement($paymentSummary, window.braintreeConstants.PP_DEBIT_CREDIT_PAYMENT_TYPE);
        } else {
            appendDivToElement(
                $paymentSummary,
                selectedPaymentInstrument.type ?
                    getPaymentMethodToLowerCase(paymentMethodId) + ' - ' +  selectedPaymentInstrument.type
                    : getPaymentMethodToLowerCase(paymentMethodId)
            );
        }

        if (selectedPaymentInstrument.maskedCreditCardNumber) {
            appendDivToElement($paymentSummary, selectedPaymentInstrument.maskedCreditCardNumber);
        }

        if (paymentMethodId === window.braintreeConstants.PAYMENT_METHOD_ID_PAYPAL) {
            appendDivToElement($paymentSummary, selectedPaymentInstrument.braintreePaypalEmail);
        } else if (paymentMethodId === window.braintreeConstants.PAYMENT_METHOD_ID_VENMO) {
            appendDivToElement($paymentSummary, selectedPaymentInstrument.braintreeVenmoUserId);
        }

        if (selectedPaymentInstrument.type) {
            appendDivToElement($paymentSummary, selectedPaymentInstrument.type);
        }

        appendDivToElement($paymentSummary, `${order.priceTotal.charAt(0)}${selectedPaymentInstrument.amount}`);
    });
}

module.exports = {
    getBillingAddressFormValues,
    updateCheckoutView,
    appendDivToElement
};
