'use strict';

const helper = require('../../helper');
const CreditCardVaultManagerModel = require('../components/creditCardVaultManagerModel');

const cardPlaceholder = '************';
const btSessionCreditAccountSelectorID = 'braintreeSessionCreditAccount';
const ATTR_DATA_EXPIRATION = 'data-expiration';

/**
 * Displays stores Credit Cards list on the Billing Page
 */
function displayStoredCreditCardList() {
    const $creditCardAccount = document.querySelector('.js-braintree-used-creditcard-account');

    $creditCardAccount.classList.remove('used-creditcard-account-hide');
    $creditCardAccount.classList.add('used-creditcard-account');
}

/**
 * Fills the Credit Card form hidden inputs with payload data
 * @param {Object} braintreeResponse Object contains the data of new payment method
 */
function fillCreditCardFormWithPayloadData(braintreeResponse) {
    const btResponse = braintreeResponse.btTokenizePayload;
    const $creditCardFieldsCardNumber = document.querySelector('input[name=dwfrm_billing_creditCardFields_cardNumber]');

    if (btResponse) {
        const btResponseDetails = btResponse.details;

        document.getElementById('braintreeCardType').value = btResponseDetails.cardType;
        document.getElementById('braintreeCardMaskNumber').value = cardPlaceholder + btResponseDetails.lastFour;
        document.getElementById('braintreeCardExpirationMonth').value = btResponseDetails.expirationMonth;
        document.getElementById('braintreeCardExpirationYear').value = btResponseDetails.expirationYear.substr(2);
        document.getElementById('braintreeCardHolder').value = btResponseDetails.cardholderName;

        if ($creditCardFieldsCardNumber) {
            $creditCardFieldsCardNumber.value = cardPlaceholder + btResponseDetails.lastFour;
        }

        document.querySelector('input[name=braintreePaymentMethodNonce]').value = btResponse.nonce;
    }
}

/**
 * Fills the session Credit Card form inputs with payload data
 * @param {Object} braintreeResponse Object contains the data of new payment method
 */
function fillSessionCreditCardFormWithPayloadData(braintreeResponse) {
    const btResponse = braintreeResponse.btTokenizePayload;
    const usedCreditCardAccount = Boolean(document.querySelector('.js-braintree-used-creditcard-account'));

    if (usedCreditCardAccount && btResponse) {
        const btResponseDetails = btResponse.details;
        const $sessionCreditCard = document.getElementById(btSessionCreditAccountSelectorID);

        // Fields filling for displaying session card fields to the buyer
        document.getElementById('braintreeCardOwnerToDisplay').textContent = btResponseDetails.cardholderName;
        document.getElementById('braintreeCardNumberToDisplay').textContent = cardPlaceholder + btResponseDetails.lastFour;
        document.getElementById('braintreeExpirationToDisplay').textContent
            = `${btResponseDetails.expirationMonth}/${btResponseDetails.expirationYear.substr(2)}`;

        // Fields filling for displaying session card option value
        $sessionCreditCard.textContent = `${$sessionCreditCard.getAttribute('data-type')}
         ${$sessionCreditCard.getAttribute('data-number')}
         ${$sessionCreditCard.getAttribute(ATTR_DATA_EXPIRATION)}
         ${$sessionCreditCard.getAttribute('data-owner')}`;
    }
}

/**
 * Fills new/session credit card attributes to available option for using them in business logic (client/server)
 * @param {Object} braintreeResponse Tokenize payload from braintree
 * @param {boolean} isSessionCard Whether session card or not, depending on Session Payments Enabled pref
 */
function setCreditCardDataAttributes(braintreeResponse, isSessionCard) {
    const $sessionCreditCard = document.getElementById(`${isSessionCard ? btSessionCreditAccountSelectorID : 'newCardAccount'}`);
    const $braintreeSaveCreditCard = document.getElementById('braintreeSaveCreditCard');

    const btResponse = braintreeResponse.btTokenizePayload;
    const btResponseDetails = btResponse.details;
    const isSavedCard = $braintreeSaveCreditCard ? $braintreeSaveCreditCard.checked : false;

    $sessionCreditCard.classList.remove('used-creditcard-account-hide');
    $sessionCreditCard.setAttribute('data-number', cardPlaceholder + btResponseDetails.lastFour);
    $sessionCreditCard.setAttribute(ATTR_DATA_EXPIRATION, `${btResponseDetails.expirationMonth}/${btResponseDetails.expirationYear.substr(2)}`);
    $sessionCreditCard.setAttribute('data-type', btResponseDetails.cardType);
    $sessionCreditCard.setAttribute('data-last-four', btResponseDetails.lastFour);
    $sessionCreditCard.setAttribute('data-owner', btResponseDetails.cardholderName);
    $sessionCreditCard.setAttribute('data-nonce', btResponse.nonce);
    $sessionCreditCard.setAttribute('data-session-account', isSessionCard);
    $sessionCreditCard.setAttribute('data-save-card', isSavedCard);
}

/**
 * Marks a session Credit Card account as selected
 */
function markAsSelectedSessionAccount() {
    const $newCCAccount = document.getElementById('newCardAccount');
    const $sessionCreditCard = document.getElementById(btSessionCreditAccountSelectorID);

    $newCCAccount.removeAttribute('selected');
    $sessionCreditCard.selected = true;
}

/**
 * Returns a Credit Card flow ID from selector
 * @param {Object} $creditCardList Selector contained the Credit Card flow ID
 * @returns {string} Credit Card flow ID
 */
function getCreditCardFlowID($creditCardList) {
    const selectedOptionValue = $creditCardList.value;
    const newCardID = 'newcard';
    const sessionCardID = 'sessioncard';
    const storedCardID = 'js-stored-card';

    let selectedOption = null;
    let creditCardFlowID = null;

    switch (selectedOptionValue) {
        case newCardID:
            creditCardFlowID = newCardID;

            break;

        case sessionCardID:
            creditCardFlowID = sessionCardID;

            break;

        default:
            selectedOption = helper.getSelectedOption($creditCardList);

            if (selectedOption.classList.contains(storedCardID)) {
                creditCardFlowID = storedCardID;
            }

            break;
    }

    return creditCardFlowID;
}

/**
 * Returns object with nonce related to stored card
 * @param {Promise} clientInstancePromise Client instance promise
 * @param {Object} $creditCardList Selector with needed data
 * @returns {Object} Response object
 */
function getNonceFromStoredCard(clientInstancePromise, $creditCardList) {
    const CreditCardVaultManagerModelInstance = new CreditCardVaultManagerModel(clientInstancePromise);
    const $selectedOption = helper.getSelectedOption($creditCardList);
    const cardType = $selectedOption.getAttribute('data-type').toLowerCase();
    const lastFour = $selectedOption.getAttribute('data-last-four').toString();
    const [month, year] = $selectedOption.getAttribute(ATTR_DATA_EXPIRATION).split('/');
    const expiration = { month, year };

    return CreditCardVaultManagerModelInstance.getNonceByCardData(lastFour, cardType, expiration);
}

/**
 * Fills 3DS data
 * @param {Object} threeDSecureDataValidationPayload 3DS information about the card
 */
function fill3DsData(threeDSecureDataValidationPayload) {
    const $braintreeIs3dSecureRequired = document.getElementById('braintreeIs3dSecureRequired');

    if (threeDSecureDataValidationPayload && threeDSecureDataValidationPayload.liabilityShifted) {
        $braintreeIs3dSecureRequired.value = 'true';
    }
}

/**
 * Returns whether Credit Card tab is active on the Billing Page
 * @returns {boolean} true/false
 */
function isActiveCreditCardTab() {
    return document
        .querySelector('.payment-options[role=tablist] a[data-toggle="tab"][href="#creditcard-content"]')
        .classList
        .contains('active');
}

/**
 * Get stored card by last four digits and card type
 * @param {string} lastFour Last four card digits
 * @param {string} cardType Card type
 * @returns {Object} Jquery object of found stored card
 */
function getStoredCardByData(lastFour, cardType) {
    return Array.from(document.querySelectorAll('.js-stored-card')).find(($card) => {
        const storedCardType = $card.dataset.type.toLowerCase();
        const storedCardLastFour = $card.dataset.lastFour.toString();

        return storedCardType === cardType && storedCardLastFour === lastFour;
    });
}

/**
 * Shows already stored card by last four digits and card type
 * @param {string} lastFour Last four card digits
 * @param {string} cardType Card type
 * @returns {boolean} true in case if stored card with provided params (lastFour, cardType) was found
 */
function showAlreadyStoredCard(lastFour, cardType) {
    const $storedCard = getStoredCardByData(lastFour, cardType);
    let flag = false;

    if ($storedCard) {
        flag = true;
    }

    return flag;
}

let base = {
    displayStoredCreditCardList,
    fillCreditCardFormWithPayloadData,
    fillSessionCreditCardFormWithPayloadData,
    setCreditCardDataAttributes,
    markAsSelectedSessionAccount,
    getCreditCardFlowID,
    getNonceFromStoredCard,
    fill3DsData,
    isActiveCreditCardTab,
    showAlreadyStoredCard
};

Object.assign(base, require('braintree_ext/braintree/creditcard/helpers/creditCardPaymentProcessingHelper'));

module.exports = base;
