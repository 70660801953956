'use strict';

/**
 * Clear Credit Card hosted fields
 */
function clearHostedFields() {
    if (!window.braintreePreferences.clearIframeCardForm) return;

    const that = this;

    Object.keys(this.fieldsOptions.configs).forEach(option => {
        that.sdkHfInstance.clear(option);
    });
};

module.exports = {
    clearHostedFields
};
