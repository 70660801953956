'use strict';

/* global braintreeUtils braintree $ */

let btClientInstancePromise;

/**
 * Returns a promise that resolves with the HostedFields instance.
 * OVERRIDEN: extended with a methods to catch firing event on CC type change and update the icon
 * @param {Object} hostedFieldsParams Object of hosted fieds parameters
 * @returns {Object} Object with hosted field parameters
 */
function createHostedFields(hostedFieldsParams) {
    return btClientInstancePromise
        .then(function (btClientInstance) {
            return braintree.hostedFields.create({
                client: btClientInstance,
                styles: hostedFieldsParams.styles,
                fields: hostedFieldsParams.configs
            });
        })
        .then((hostedFieldsInstance) => {
            const cardIconUseEl = document.querySelector('.braintree-card-icon use');
            hostedFieldsInstance.on('cardTypeChange', function (event) {
                const cardsList = event.cards;
                if (!event.fields?.number?.isEmpty && cardsList.length !== 0 && !!cardsList[0].type && cardIconUseEl) {
                    cardIconUseEl.setAttribute('xlink:href', '#icon-' + cardsList[0].type);
                } else {
                    cardIconUseEl.setAttribute('xlink:href', '#iconCardFront');
                }
            });

            return hostedFieldsInstance;
        })
        .catch(console.error);
}

/**
 *
 * @param {Promise} braintreeClientInstancePromise Braintree Credit Card instance promise
 */
function init(braintreeClientInstancePromise) {
    btClientInstancePromise = braintreeClientInstancePromise;
}

module.exports = {
    init,
    createHostedFields
};
