'use strict';

/**
 * Returns Credit Card hosted fileds styles
 * @returns {Object} Object of styles
 */
function getHostedFieldsStyles() {
    return {
        input: {
            'font-size': '1rem',
            'color': '#2e2e2e',
            'font-family': 'Avenir Next LT W05 Regular,sans-serif',
            'letter-spacing': '0.05em'
        }
    };
}

module.exports = {
    getHostedFieldsStyles
};
