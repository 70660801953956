'use strict';

/**
 * Displays stores Credit Cards list on the Billing Page
 */

/**
 * Returns whether Credit Card tab is active on the Billing Page
 * @override added additional check if selected payment method CREDIT_CARD for casses when dynamically set COUPON_CODE or GIFT_CERTIFICATE
 * @returns {boolean} true/false
 */
function isActiveCreditCardTab() {
    return document
        .querySelector('.payment-options[role=tablist] a[data-toggle="tab"][href="#creditcard-content"]')
        ?.classList
        .contains('active') && $('.payment-information').data('payment-method-id') === 'CREDIT_CARD';
}

module.exports = {
    isActiveCreditCardTab
};
