'use strict';

const baseModule = require('base_braintree/braintree/paymentMethodGeneral');
const helper = require('base_braintree/braintree/helper');

/**
 * Method override default SFRA and int_braintree payment-details filling behavior.
 * Method fill payment-details block with the right payment methdo data (like name, account, etc.)
 */
baseModule.fillPaymentSummaryContainer = function () {
    const $summaryDetails = document.querySelector('.summary-details .payment-details');
    const selectedPaymentMethod = $('.payment-information').data('payment-method-id');

    if ($summaryDetails && ($summaryDetails.dataset.braintreePaymentMethods || '').includes(selectedPaymentMethod)) {
        $summaryDetails.classList.add('js-braintree-payment-details');
        $summaryDetails.classList.remove('payment-details');

        $('body').on('checkout:updateCheckoutView', helper.updateCheckoutView);
    }
};

module.exports = baseModule;
